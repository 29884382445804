import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const AUTH_API = environment.url;

@Injectable({
    providedIn: 'root',
})
export class SecondHandOrdersService {
    constructor(private http: HttpClient) { }

    async getReturns(idBrand: any, dateI: any, dateF: any) {
        let data = await this.http
            .get<any>(
                AUTH_API +
                '2handOrder?id_brand=' + idBrand + '&inicial_date=' +
                dateI +
                '&final_date=' +
                dateF
            )
            .toPromise();
        return data;
    }


    async get2HandOrderStatus() {
        let data = await this.http
            .get<any>(AUTH_API + '2handOrder/2handordertatus')
            .toPromise();
        return data;
    }

    async getReturnLines(id_order: any) {
        let data = await this.http
            .get<any>(AUTH_API + '2handOrder/2handorderlines?id_order=' + id_order)
            .toPromise();
        return data;
    }

    async get2handOrderLabel(id_order: any) {
        let data = await this.http
            .get<any>(AUTH_API + '2handOrder/2handOrderlabel?id_order=' + id_order)
            .toPromise();
        return data;
    }

    async post2handOrderSentToBuyer(order: any) {
        let data = await this.http.put<any>(AUTH_API + '2handOrder/2handOrderSentToBuyer', order).toPromise();
        return data;
    }

    async putFulfillStatus(fulfill: any) {
        let data = await this.http.put<any>(AUTH_API + 'order/fulfillOrdersCiclo', fulfill).toPromise();
        return data;
    }
}
