<div *ngIf="loading" class="flex justify-content-center align-items-center min-h-screen">
	<div class="loading"></div>
</div>
<div *ngIf="!loading" class="h-full">
	<p-toast></p-toast>
	<p-table #dtreturns [value]="secondHandOrders" dataKey="idInterno" [columns]="selectedColumns" [rowHover]="true"
		[paginator]="true" [globalFilterFields]="['idInterno', 'order_name', 'customer', 'country']" [rows]="25"
		[rowsPerPageOptions]="[25,50,100]" [showCurrentPageReport]="true" [resizableColumns]="true"
		[reorderableColumns]="true" responsiveLayout="scroll" [responsive]="true" [(selection)]="selectedSecondHand"
		exportFilename="secondHandOrders" [loading]="loading"
		currentPageReportTemplate="{{ 'products.list_product.current_page_report' | translate:{'first':'{{first}}','last':'{{last}}', 'totalRecords':'{{totalRecords}}'} }}">
		<ng-template pTemplate="caption">
			<div class="flex flex-column gap-2">
				<div class="flex justify-content-between gap-2 flex-wrap">
					<div class="flex flex-column w-full sm:w-auto sm:flex-row gap-2">
						<p-calendar [showIcon]="true" [(ngModel)]="rangeDates" selectionMode="range"
							dateFormat="dd/mm/yy" (onClose)="ngOnInit()" [minDate]="minimumDate"></p-calendar>
						<div class="flex gap-2 w-full sm:w-auto">
							<span class="p-input-icon-left w-full sm:w-auto">
								<i class="pi pi-search"></i>
								<input pInputText type="text" #filter
									(input)="dtreturns.filterGlobal($event.target.value, 'contains')"
									placeholder="{{ 'general.search_here' | translate }}" />
							</span>
							<div (click)="init2HandOrders()" class="flex align-items-center cursor-pointer"
								style="color: #6c757d;">
								<i class="pi pi-refresh"></i>
							</div>
						</div>
					</div>
					<div class="flex gap-2">
						<button pButton pRipple label="Export" class="p-button-outlined" (click)="exportCSV()">
							<span class="p-button-icon p-button-icon-left flex"><i class="pi pi-file-excel"></i></span>
						</button>
						<button pButton pRipple label="Go To Marketplace" class="p-button-secundary"
							(click)="openMarketPlace()">
							<span class="p-button-icon p-button-icon-left flex"></span>
						</button>
					</div>
				</div>
				<div class="flex align-items-center justify-content-start gap-1">
					<label>Show order fulfilled?</label>
					<p-inputSwitch [(ngModel)]="filterTableStatus" (onChange)="filterTableByStatus()" class="flex"></p-inputSwitch>
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th style="width: 1rem">
					<p-tableHeaderCheckbox (click)="mainCheckboxClick()"></p-tableHeaderCheckbox>
				</th>
				<th pResizableColumn pReorderableColumn [pSortableColumn]="col.sort ? col.field : null"
					*ngFor="let col of columns" class="cursor-move">
					<div class="flex align-items-center"
						[ngClass]="{'cursor-pointer' : col.sort || col.filter, 'p-column-filter-menu': col.filter }">
						{{col.header}}
						<p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
						<i *ngIf="col.filter && activeStatus == null" class="pi pi-filter"
							(click)="openOverlay($event, op)"></i>
						<i *ngIf="col.filter && activeStatus != null" class="pi pi-filter-fill"
							(click)="openOverlay($event, op)"></i>
					</div>
				</th>
				<th style="width: 1rem; border-left: none;">
					<img src="assets/layout/images/config-icon.svg" (click)="openOverlay($event, op2)"
						class="cursor-pointer">
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-item let-columns="columns" let-ri="rowIndex">
			<tr (click)="openSideBar(item)" class="cursor-pointer">
				<td>
					<p-tableCheckbox [value]="item" (click)="checkboxClick($event)"></p-tableCheckbox>
				</td>
				<td *ngFor="let col of columns">
					<div class="flex w-min"
						[ngClass]="col.field == 'orderStatus' ? 'secondHandOrder-badge status-' + item.IDStatus : ''">
						<div *ngIf="col.field === 'dataCriacao'">
							{{ item.dataCriacao | date:'MMM d, h:mm a' }}
						</div>
						<div *ngIf="col.field != 'idInterno' && col.field !== 'dataCriacao'">
							{{item[col.field]}}
						</div>
						<a *ngIf="col.field == 'idInterno'">
							{{item[col.field]}}
						</a>
						<div *ngIf="col.field == 'items'">
							<div *ngIf="item[col.field] == 1">&nbsp;item</div>
							<div *ngIf="item[col.field] !== 1">&nbsp;items</div>
						</div>
						<div *ngIf="col.field == 'refund_total'">
							&nbsp;{{item.currency}}
						</div>
					</div>
				</td>
				<td></td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="11">{{ 'general.no_data' | translate }}</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="loadingbody">
			<tr>
				<td colspan="11">{{ 'general.loading' | translate }}</td>
			</tr>
		</ng-template>
	</p-table>
</div>
<p-overlayPanel #op2 (onHide)="hideOverlay()" (onShow)="showOverlay()">
	<ng-template pTemplate>
		<div class="flex flex-column gap-3">
			<h6>CUSTOMIZE COLUMNS</h6>
			<div>
				<div *ngFor="let col of colsOptional" class="field-checkbox">
					<p-checkbox name="columns" value="col" [value]="col" [(ngModel)]="selectedColumnsAux"
						[inputId]="col.field"></p-checkbox>
					<label [for]="col.field">{{col.header}}</label>
				</div>
			</div>
		</div>
		<div class="mt-6 flex">
			<p-button icon="pi pi-replay" (click)="revert($event, op2)" label="Revert to default"
				styleClass="p-button-text"></p-button>
			<p-button label="Customize" (click)="customize($event, op2)"></p-button>
		</div>
	</ng-template>
</p-overlayPanel>
<p-sidebar [(visible)]="visibleSidebar" position="right" [baseZIndex]="10000" (onHide)="hideSideBar()"
	(click)="menuShow = false" [style]="{width: '85%', padding: '0px 0px 0px 30px'}" [showCloseIcon]="false"
	class="relative">
	<div class="p-sidebar-header" style="padding: 1.25rem 0">
		<div>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-sidebar-close"
				(click)="hideSideBar()"></button>
		</div>
	</div>
	<div *ngIf="!secondHand || loadingSidebar" class="flex justify-content-center align-items-center min-h-screen">
		<div class="loading"></div>
	</div>
	<div *ngIf="secondHand && !loadingSidebar" class="flex flex-column">
		<div class="card">
			<!-- mb-3 -->
			<div class="flex justify-content-between flex-wrap row-gap-3">
				<div class="flex flex-column gap-2">
					<div class="flex flex-row align-items-center gap-2">
						<h5>2Hand Order | {{secondHand.id_order_header}}</h5>
						<div [class]="'secondHandOrder-badge status-' + secondHand.id_order_status">
							{{secondHand.description}}</div>
					</div>
					<div>{{secondHand.order_type_description}} <span *ngIf="secondHand.id_order_type == 1"
							class="ordertype"
							[ngClass]="{'ordertype-status-1' : secondHand?.labelsVerify?.tracking_number, 'ordertype-status-0' : !secondHand?.labelsVerify?.tracking_number}">{{secondHand?.labelsVerify?.tracking_number
							? 'Approved' :
							'On hold'}}</span></div>
				</div>
				<div class="flex flex-wrap gap-2 justify-content-end"
					*ngIf=" this.labels.data!= null && this.labels.data.length > 0">
					<button *ngIf="secondHand.id_brand == 5" pButton pRipple label="Fulfill Items" class="p-button-outlined"
						(click)="openSideBar2()">
						<span class="p-button-icon p-button-icon-left flex">
							<i class="pi pi-check-circle"></i>
						</span>
					</button>
					<button pButton pRipple label="Download labels" class="p-button-outlined"
						(click)="requestLabel(secondHand.id_order_header)">
						<span class="p-button-icon p-button-icon-left flex">
							<i class="pi pi-download"></i>
						</span>
					</button>
				</div>
				<!--<div class="relative flex flex-column align-items-end">
					<p-button *ngIf="app.isDesktop()" label="More actions" icon="pi pi-angle-down" iconPos="right"
						class="p-button-outlined" (click)="menu.toggle($event)"></p-button>
					<button *ngIf="secondHand.id_order_line_status !== 9 && !app.isDesktop()" pButton pRipple type="button"
						icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text relative p-0 h-min"
						(click)="menu.toggle($event)"></button>
					<p-menu #menu [popup]="true" [model]="dropdown_options"></p-menu>
				</div>-->
			</div>
			<div class="mt-3 grid">
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
					<span>Date: {{secondHand.created_at}}</span>
				</div>
				<div *ngIf="secondHand?.labelsVerify" class="col-12 sm:col-6 md:col-6 lg:col-4">
					<span>{{ 'orders.returns.tracking' | translate }}:
						{{secondHand?.labelsVerify.tracking_number}}</span>
				</div>
				<div *ngIf="secondHand?.labelsVerify" class="col-12 sm:col-6 md:col-6 lg:col-4">
					<span>{{ 'orders.returns.carrier' | translate }}:
						{{secondHand?.labelsVerify.carrier_name}}</span>
				</div>
				<div *ngIf="secondHand?.labelsVerify" class="col-12 sm:col-6 md:col-6 lg:col-4">
					<span>{{ 'orders.returns.status' | translate }}: {{secondHand?.labelsVerify.delivered ?
						('orders.orders.delivered' | translate) : ('orders.orders.not_delivered' | translate) }}</span>
				</div>
			</div>
		</div>
		<!--quando carregas na listas que está em em main/returns-->
		<div>
			<div class="card w-full">
				<h5>{{ 'orders.returns.customer_details' | translate }}</h5>
				<div class="mt-3 grid">
					<div class="col-12 sm:col-6">
						<p>{{ 'orders.returns.customer' | translate }}: <a>{{secondHand.first_name}}
								{{secondHand.last_name}}</a>
						</p>
					</div>
					<div class="col-12 sm:col-6">
						<div class="flex flex-wrap gap-1">
							<p class="mb-0">{{ 'orders.returns.email' | translate }}:</p>
							<div class="flex gap-1">
								<p class="mb-0"><a href="mailto:{{secondHand.email}}"
										(click)="$event.stopPropagation()">{{secondHand.email}}</a></p>
								<p-button (click)="copiedToclipboard()" icon="pi pi-copy"
									styleClass="h-auto w-auto p-0 text-color p-button-rounded p-button-text"
									ngxClipboard [cbContent]="secondHand.email"></p-button>
							</div>
						</div>
					</div>
					<div class="col-12 sm:col-6">
						<p>{{ 'orders.returns.addressS' | translate }}: {{secondHand.shipping_address_line_1}}
							{{secondHand.shipping_address_line_2}} {{secondHand.shipping_city}}
							{{secondHand.shipping_zipcode}}
							{{secondHand.country_code_name}}</p>
					</div>
					<div class="col-12 sm:col-6">
						<p>{{ 'orders.returns.addressB' | translate }}: {{secondHand.billing_address_line_1}}
							{{secondHand.billing_address_line_2}}
							{{secondHand.billing_city}} {{secondHand.billing_zipcode}} {{secondHand.country_codeB_name}}
						</p>
					</div>
					<!-- <div class="col-12 sm:col-6">
						<p>{{ 'orders.returns.payment_method' | translate }}: XXX</p>
					</div> -->
				</div>
			</div>
			<div class="returns-summary flex flex-column ">
				<div class="w-full flex flex-column lg:flex-row gap-3">
					<div class="card w-full lg:w-8" [ngClass]="{'w-full' : secondHand?.order_name?.includes('exc.')}">
						<h5>Items</h5>
						<div class="flex align-items-start justify-content-center">
							<p-dataView class="w-full" #dv [value]="secondHand.lines" [paginator]="false" layout="list">
								<ng-template let-returnline pTemplate="listItem">
									<div class="w-full" *ngIf="returnline.IDStatus !== 14">
										<div id="info-return"
											class="info-return flex flex-row flex-wrap gap-4 pt-5 pb-5 w-full justify-content-between">
											<div
												class="w-full flex flex-column md:flex-row justify-content-between gap-3">
												<div class="flex flex-column w-full min-w-max gap-3">
													<div class="flex flex-column sm:flex-row gap-3 w-full">
														<div id="block-img-product"
															class="flex flex-wrap w-full sm:flex-column gap-3 sm:w-min block-img-product p-0">
															<img [src]="returnline.originalPhoto"
																[alt]="returnline.productName"
																class="w-7rem shadow-2" />
														</div>
														<div class="md:text-left flex flex-column gap-3 max-w-full">
															<!--nome do itens-->
															<span
																class="align-items-start justify-content-start"><b>{{returnline.productName}}</b></span>
															<div class="flex align-items-center">
																<span
																	class="mr-3 align-self-center md:align-self-end"><b>SKU:
																	</b>{{returnline.sku}}</span>
															</div>
															<div class="flex align-items-center">
																<span
																	class="mr-3 align-self-center md:align-self-end"><b>EAN:
																	</b>{{returnline.ean}}</span>
															</div>
															<div class="flex">
																<div *ngIf="returnline.product_variant_option1 != null"
																	class="mr-3 ">
																	<span
																		class="align-items-start justify-content-start"><b>
																			{{returnline.product_variant_option1}}: </b>
																		{{returnline.color}}</span>
																</div>
																<div *ngIf="returnline.product_variant_option2 != null"
																	class="mr-3 ">
																	<span
																		class="align-items-start justify-content-start"><b>
																			{{returnline.product_variant_option2}}: </b>
																		{{returnline.size}}</span>
																</div>
																<div *ngIf="returnline.product_variant_option3 != null"
																	class="mr-3 ">
																	<span
																		class="align-items-start justify-content-start"><b>
																			{{returnline.product_variant_option3}}: </b>
																		{{returnline.option3}}</span>
																</div>
															</div>
															<div
																*ngIf="[49,50,51].includes(returnline.IDStatus) == false">
																<b>{{'orders.returns.price' | translate }}: </b>
																<span class="align-items-start justify-content-start">
																	{{returnline.secondHandPrice}}
																	{{secondHand.currency}}</span>
															</div>
															<div>
																<b>{{'orders.returns.condition' | translate }}: </b>
																<span class="align-items-start justify-content-start">
																	{{returnline.condition}}</span>
															</div>
															<!--<div class="flex align-items-center">
															<span class="mr-3 align-self-center md:align-self-end"><b>
																	{{
																	'orders.returns.quantity' | translate }}: </b>
																{{returnline.quantity}}</span>
															<span class="mr-3 align-self-center md:align-self-end"><b>
																	{{
																	'orders.returns.received' | translate }}: </b>
																{{returnline.quantityReceived}}</span>
														</div>-->
															<div
																*ngIf="[49,50,51].includes(returnline.IDStatus) == false && returnline.return_reason_id">
																<span
																	class="align-items-start justify-content-start"><b>
																		{{
																		'orders.returns.reason' | translate }}: </b>
																	{{returnline.return_reason_text}}</span>
															</div>
															<!--<div
															*ngIf="[49,50,51].includes(returnline.IDStatus) == false && returnline.return_reason_note">
															<span class="align-items-start justify-content-start"><b> {{
																	'orders.returns.note' | translate }}: </b>
																{{returnline.return_reason_note}}</span>
														</div>
														<div
															*ngIf="[49,50,51,53,57].includes(returnline.IDStatus) == true">
															<p-button label="Order Exchange {{secondHand.order_name_exc}}"
																styleClass="p-button-link p-0"
																(click)="getOrderExchangeDetails( secondHand.order_id_exc , op, $event)">
															</p-button>
														</div>-->
														</div>
													</div>
													<div
														*ngIf="(returnline.photos > 0 && returnline.images_data?.length == undefined)">
														<p-button
															(click)="onImagesClick(returnline.id_return,returnline.id_return_line)"
															label="{{ 'orders.returns.images' | translate }}"
															[badge]="returnline.photos"
															styleClass="p-button-outlined p-button-secondary">
														</p-button>
													</div>
													<div *ngIf="returnline.photos?.length > 0 ">
														<p-dataView [value]="returnline.photos">
															<ng-template let-linesImages pTemplate="listItem">
																<div class="mx-2">
																	<p-image [src]="linesImages.url_link" width="50px"
																		[preview]="true">
																	</p-image>
																</div>
															</ng-template>
														</p-dataView>
													</div>
												</div>
												<div class="w-auto flex flex-column gap-3">
													<span class="flex align-items-center justify-content-start gap-1">
														<span *ngIf="returnline.review != -1"
															class="w-full flex flex-wrap gap-2 align-items-center">
															<span class="flex align-items-center gap-3">
																<b class="white-space-nowrap"> {{
																	'orders.returns.customer_review' | translate }}:
																</b>
																<div *ngIf="returnline.review"
																	class="review review-status-1">
																	<i class="pi pi-thumbs-up"></i>
																</div>
																<div *ngIf="!returnline.review"
																	class="review review-status-0">
																	<i class="pi pi-thumbs-down"></i>
																</div>
															</span>
															<div *ngIf="returnline.review_comments">
																"{{returnline.review_comments}}"</div>
														</span>
														<span *ngIf="returnline.review == -1">
															<div>
																<b class="white-space-nowrap"> {{
																	'orders.returns.customer_review' | translate }}:
																</b> {{
																'orders.returns.not_reviewed' |
																translate }}
															</div>
														</span>
													</span>
												</div>
											</div>
											<div class="card col-12 flex flex-column md:flex-row gap-3">
												<div class="w-full">
													<h6>{{ 'orders.returns.seller_details' | translate }}</h6>
													<div class="mt-2 grid">
														<div class="col-12">
															<p>{{ 'orders.returns.name' | translate }}:
																<a>{{returnline.FirstName}}{{returnline.LastName}}</a>
															</p>
														</div>
														<div class="col-12">
															<div class="flex flex-wrap gap-1">
																<p class="mb-0">{{ 'orders.returns.email' | translate
																	}}:
																</p>
																<div class="flex gap-1">
																	<p class="mb-0"><a
																			href="mailto:{{returnline.EmailAddress}}"
																			(click)="$event.stopPropagation()">{{returnline.EmailAddress}}</a>
																	</p>
																	<p-button (click)="copiedToclipboard()"
																		icon="pi pi-copy"
																		styleClass="h-auto w-auto p-0 text-color p-button-rounded p-button-text"
																		ngxClipboard
																		[cbContent]="returnline.EmailAddress"></p-button>
																</div>
															</div>
														</div>
														<div class="col-12">
															<p>{{ 'orders.returns.address' | translate }}:
																{{returnline.shipping_address_line_1}}
																{{returnline.shipping_address_line_2}}
																{{returnline.shipping_city}}
																{{returnline.shipping_zipcode}}
																{{returnline.shipping_country}}</p>
														</div>
													</div>
												</div>
												<p-divider class="hidden md:flex divider-line"
													layout="vertical"></p-divider>
												<div class="w-full">
													<h6>{{ 'orders.returns.shipping_details' | translate }}
													</h6>
													<div class="mt-2 grid">
														<div *ngIf="returnline.shipping_type" class="col-12">
															<p>{{ 'orders.returns.type' | translate }}:
																{{returnline.shipping_type}}
															</p>
														</div>
														<div class="col-12">
															<p>{{ 'orders.returns.tracking' | translate }}:
																{{returnline.tracking_number}}
															</p>
														</div>
														<div class="col-12">
															<p>{{ 'orders.returns.carrier' | translate }}:
																{{returnline.carrier}}
															</p>
														</div>
														<div class="col-12">
															<p>{{ 'orders.returns.status' | translate }}:
																{{returnline.delivered ? ('orders.orders.delivered' |
																translate) :
																('orders.orders.not_delivered' | translate) }}
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</ng-template>
							</p-dataView>
						</div>
					</div>
					<div class="card w-full lg:w-4">
						<div class="flex-1 align-items-start justify-content-start">
							<div *ngIf="!all_exchange">
								<div class="flex justify-content-between mb-3">
									<span class="flex align-items-center justify-content-center font-bold text-xl">{{
										'orders.returns.summary' | translate }}</span>
								</div>
								<div class="flex justify-content-between mb-2">
									<span class="flex align-items-start justify-content-start">{{
										'orders.returns.subtotal' | translate }}</span>
									<span
										class="flex align-items-center justify-content-end">{{subtotal}}{{secondHand.currency}}</span>
								</div>
								<div class="flex justify-content-between mb-2">
									<span class="flex align-items-start justify-content-start">{{
										'orders.returns.shippingcost' | translate }}</span>
									<span
										class="flex align-items-center justify-content-end">{{secondHand.shipping_cost}}{{secondHand.currency}}</span>
								</div>
								<div class="flex justify-content-between flex-wrap mb-2">
									<span class="flex align-items-start justify-content-start">{{
										'orders.returns.processingfee2h' | translate }}</span>
									<span *ngIf="(all_exchange == true)"
										class="flex align-items-center justify-content-end">0.00{{secondHand.currency}}</span>
									<span class="flex align-items-center justify-content-end">
										<div *ngIf="secondHand.fee_total !== '0.00'"></div>
										{{secondHand.fee_total}}{{secondHand.currency}}
									</span>
								</div>
								<div class="flex justify-content-between flex-wrap mt-3 mb-2">
									<span class="flex align-items-start justify-content-start font-bold">{{
										'orders.orders.total' | translate }}</span>
									<span
										class="flex align-items-center justify-content-end font-bold">{{refund_total}}{{secondHand.currency}}</span>
								</div>
								<button pButton class="w-full button-secundary mt-3 mb-2"
									*ngIf="(secondHand?.labelsVerify?.id_label != null && secondHand?.id_order_type == 1 && !secondHand?.labelsVerify?.tracking_number)"
									(click)="sentToBuyer(secondHand.id_order_header)"
									label="{{'orders.returns.generate_label' | translate}}"></button>
							</div>
							<div *ngIf="all_exchange">
								<div class="flex justify-content-between mb-3">
									<span class="flex align-items-center justify-content-center font-bold text-xl">{{
										'orders.returns.summary' | translate }}</span>
								</div>
								<div class="flex justify-content-between mb-2">
									<span class="flex align-items-start justify-content-start font-bold">Items to
										exchange</span>
									<span class="flex align-items-center justify-content-end">{{itemsToExchangeCount}}
										items</span>
								</div>
								<div class="flex justify-content-between mb-2">
									<span class="flex align-items-start justify-content-start font-bold">Approved
										items</span>
									<span class="flex align-items-center justify-content-end">{{itemsApprovedCount}}
										items</span>
								</div>
								<div class="flex justify-content-between flex-wrap mb-2">
									<span class="flex align-items-start justify-content-start font-bold">Rejected
										items</span>
									<span class="flex align-items-center justify-content-end">{{itemsRejectedCount}}
										items</span>
								</div>
							</div>
							<!-- <p-divider *ngIf="(return.IDStatus === 1 || return.IDStatus === 49)" layout="horizontal">
						</p-divider> -->
							<button *ngIf="(secondHand.IDStatus === 1 || secondHand.IDStatus === 49)"
								(click)="ReturnFinalize()" pButton class="w-full button-secundary mt-3 mb-2"
								label="{{ 'general.finalize' | translate }}"></button>
							<!-- <div *ngIf="loading"
								class="p-datatable-loading-overlay p-component-overlay ng-star-inserted"
								style="z-index: 1000; display: flex; justify-content: center; align-items: center;">
								<i class="p-datatable-loading-icon pi pi-spin pi-spinner"></i>
							</div> -->
							<!-- <div *ngIf="loading" class="flex justify-content-center align-items-center min-h-screen">
								<div class="loading"></div>
							</div> -->
						</div>
					</div>
				</div>
				<!-- <div class="lg:col-1" *ngIf="all_exchange == false">
					<p-divider class="divider-line" layout="vertical"></p-divider>
				</div> -->
				<!-- Extra lines -->
				<div class="w-full flex flex-column md:flex-row gap-3 ">
					<div class="card w-full flex gap-2" *ngIf="secondHand.linesExtra?.length > 0">
						<div class="w-full">
							<div class="flex gap-3">
								<div class="w-full lg:w-8">
									<h5>{{ 'orders.returns.itemsnotexpected' | translate }}</h5>
								</div>
								<div *ngIf="secondHand.IDStatus == 13 || secondHand.IDStatus == 51"
									class="hidden lg:flex md:w-4" style="padding: 0 21px">
									<h5>{{ 'orders.returns.newexchangeorder' | translate }}</h5>
								</div>
							</div>
							<div class="flex gap-2">
								<div class="flex flex-column align-items-start justify-content-center w-full">
									<p-dataView class="w-full" #dv [value]="secondHand.linesExtra" [paginator]="false"
										layout="list">
										<ng-template let-returnline pTemplate="listItem">
											<div class="w-full flex flex-column lg:flex-row gap-2">
												<div id="info-return"
													class="w-full lg:w-8 info-return flex flex-row pt-5 pb-5 lg:pr-3 justify-content-between"
													*ngIf="returnline.IDStatus === 14">
													<div class="flex flex-column sm:flex-row gap-3">
														<div id="block-img-product"
															class="flex flex-wrap w-full sm:flex-column gap-3 sm:w-min block-img-product p-0">
															<img [src]="returnline.Image_url"
																[alt]="returnline.productname"
																class="w-7rem sm:w-10rem shadow-2" />
														</div>
														<div
															class="md:text-left flex flex-column gap-3 w-full white-space-nowrap">
															<span
																class="align-items-start justify-content-start"><b>{{returnline.ProductName}}</b></span>
															<div class="flex flex-wrap gap-3 align-items-start">
																<span class="align-self-start md:align-self-end"><b>
																		Sku:
																	</b>
																	{{returnline.sku}}</span>
																<span class="align-self-start md:align-self-end"><b>
																		{{
																		'orders.returns.order' | translate }}: </b>
																	{{secondHand.order_name}}</span>
															</div>
															<div class="flex flex-wrap gap-3 align-items-start">
																<span class="align-self-start md:align-self-end"><b>
																		{{
																		'orders.returns.quantity' | translate }}:
																	</b>
																	{{returnline.quantity}}</span>
																<span class="align-self-start md:align-self-end"><b>
																		{{
																		'orders.returns.received' | translate }}:
																	</b>
																	{{returnline.quantityReceived}}</span>
															</div>
															<div><span
																	class="align-items-start justify-content-start"><b>
																		{{ 'orders.returns.price' | translate }}:
																	</b>
																	{{returnline.price}} {{secondHand.currency}}</span>
															</div>
															<div
																*ngIf="returnline.product_variant_option1.value != null">
																<span
																	class="align-items-start justify-content-start"><b>
																		{{returnline.product_variant_option1.key}}:
																	</b>
																	{{returnline.product_variant_option1.value}}</span>
															</div>
															<div
																*ngIf="returnline.product_variant_option2.value != null">
																<span
																	class="align-items-start justify-content-start"><b>
																		{{returnline.product_variant_option2.key}}:
																	</b>
																	{{returnline.product_variant_option2.value}}</span>
															</div>
															<div
																*ngIf="returnline.product_variant_option3.value != null">
																<span
																	class="align-items-start justify-content-start"><b>
																		{{returnline.product_variant_option3.key}}:
																	</b>
																	{{returnline.product_variant_option3.value}}</span>
															</div>
														</div>
													</div>

												</div>


											</div>
										</ng-template>
									</p-dataView>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</p-sidebar>

<p-sidebar [(visible)]="visibleSidebar2" position="right" [baseZIndex]="10000" (onHide)="hideSideBar2()"
	(click)="menuShow = false" [style]="{width: '85%', padding: '0px 0px 0px 30px'}" [showCloseIcon]="false"
	class="relative">
	<div class="p-sidebar-header" style="padding: 1.25rem 0">
		<div>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-sidebar-close"
				(click)="hideSideBar2()"></button>
		</div>
	</div>
	<div *ngIf="!secondHand || loadingSidebar" class="flex justify-content-center align-items-center min-h-screen">
		<div class="loading"></div>
	</div>
	<div *ngIf="secondHand && !loadingSidebar" class="flex flex-column">
		<!--quando carregas na listas que está em em main/returns-->
		<div>
			
			<div class="returns-summary flex flex-column ">
				<div class="w-full flex flex-column lg:flex-row gap-3">
					<div class="card w-full lg:w-11" [ngClass]="{'w-full' : secondHand?.order_name?.includes('exc.')}">
						<h5>Items</h5>
						<div class="flex align-items-start justify-content-center">
							<p-dataView class="w-full" #dv [value]="secondHand.lines" [paginator]="false" layout="list">
								<ng-template let-returnline pTemplate="listItem">
									<div class="w-full" *ngIf="returnline.IDStatus !== 14">
										<div id="info-return"
											class="info-return flex flex-row flex-wrap gap-4 pt-5 pb-5 w-full justify-content-between">
											<div
												class="w-full flex flex-column md:flex-row justify-content-between gap-3">
												<div class="flex flex-column w-full min-w-max gap-3">
													<div class="flex flex-column sm:flex-row gap-3 w-full">
														<div id="block-img-product"
															class="flex flex-wrap w-full sm:flex-column gap-3 sm:w-min block-img-product p-0">
															<img [src]="returnline.originalPhoto"
																[alt]="returnline.productName"
																class="w-7rem shadow-2" />
														</div>
														<div class="md:text-left flex flex-column gap-3 max-w-full">
															<!--nome do itens-->
															<span
																class="align-items-start justify-content-start"><b>{{returnline.productName}}</b></span>
															<div class="flex align-items-center">
																<span
																	class="mr-3 align-self-center md:align-self-end"><b>SKU:
																	</b>{{returnline.sku}}</span>
															</div>
															<div class="flex align-items-center">
																<span
																	class="mr-3 align-self-center md:align-self-end"><b>EAN:
																	</b>{{returnline.ean}}</span>
															</div>
															<div class="flex">
																<div *ngIf="returnline.product_variant_option1 != null"
																	class="mr-3 ">
																	<span
																		class="align-items-start justify-content-start"><b>
																			{{returnline.product_variant_option1}}: </b>
																		{{returnline.color}}</span>
																</div>
																<div *ngIf="returnline.product_variant_option2 != null"
																	class="mr-3 ">
																	<span
																		class="align-items-start justify-content-start"><b>
																			{{returnline.product_variant_option2}}: </b>
																		{{returnline.size}}</span>
																</div>
																<div *ngIf="returnline.product_variant_option3 != null"
																	class="mr-3 ">
																	<span
																		class="align-items-start justify-content-start"><b>
																			{{returnline.product_variant_option3}}: </b>
																		{{returnline.option3}}</span>
																</div>
															</div>
															<div>
																<b>{{'orders.returns.condition' | translate }}: </b>
																<span class="align-items-start justify-content-start">
																	{{returnline.condition}}</span>
															</div>
															<div
																*ngIf="[49,50,51].includes(returnline.IDStatus) == false && returnline.return_reason_id">
																<span
																	class="align-items-start justify-content-start"><b>
																		{{
																		'orders.returns.reason' | translate }}: </b>
																	{{returnline.return_reason_text}}</span>
															</div>
														</div>
													</div>
													<div
														*ngIf="(returnline.photos > 0 && returnline.images_data?.length == undefined)">
														<p-button
															(click)="onImagesClick(returnline.id_return,returnline.id_return_line)"
															label="{{ 'orders.returns.images' | translate }}"
															[badge]="returnline.photos"
															styleClass="p-button-outlined p-button-secondary">
														</p-button>
													</div>
													<div *ngIf="returnline.photos?.length > 0 ">
														<p-dataView [value]="returnline.photos">
															<ng-template let-linesImages pTemplate="listItem">
																<div class="mx-2">
																	<p-image [src]="linesImages.url_link" width="50px"
																		[preview]="true">
																	</p-image>
																</div>
															</ng-template>
														</p-dataView>
													</div>
												</div>
												<div class="w-3 flex flex-column gap-7">
													<span class="flex align-items-center justify-content-start gap-1">
														<p-checkbox name="fulfillArray" [value]="returnline.id_order_line" [(ngModel)]="selectedProducts" checked="returnline.fulfillment" (click)="checkboxClick($event)"></p-checkbox>
                                                            {{'Fulfill Item' | translate }}
													</span>
												</div>
											</div>
										</div>
									</div>
								</ng-template>
							</p-dataView>
						</div>
						<button pButton pRipple label="Fulfill Items" class="p-button-secundary"
							(click)="fulfillItems()">
							<span class="p-button-icon p-button-icon-left flex">
								<i class="pi pi-check-circle"></i>
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</p-sidebar>

<p-overlayPanel #op [showCloseIcon]="true" styleClass="w-9 sm:w-25rem max-w-11">
	<ng-template pTemplate>
		<div class="text-lg mb-3">
			<b> {{
				'orders.returns.orderstate' | translate }}: </b>
			<span
				[class]="'product-badge status-' + order_exc_detail.financial_status.toLowerCase()">{{order_exc_detail.financial_status}}</span>
		</div>

		<!-- <div class="col-12 lg:col-8 flex align-items-start justify-content-center"> -->
		<p-virtualScroller [value]="order_exc_detail.exc_lines" scrollHeight="180px">
			<ng-template pTemplate="ListItem" let-exc_lines>
				<div class="col-12 mb-3">
					<div class="flex p-0 w-full">
						<div class="w-8rem mr-3"><img [src]="exc_lines.image_url" [alt]="exc_lines.productname"
								class="w-full shadow-2 mr-3" /></div>
						<div class="w-full md:text-left">
							<div class="font-bold text-lg">{{exc_lines.name_aux}}</div>
							<div class="my-1"><span class="align-items-start justify-content-start"><b>
										Sku: </b> {{exc_lines.sku}}</span></div>
							<div class="my-1"><span class="align-items-start justify-content-start"><b>
										{{ 'orders.returns.quantity' | translate }}: </b>
									{{exc_lines.quantity}}</span></div>
							<div *ngIf="exc_lines.product_variant_option1.value != null" class="my-1"><span
									class="align-items-start justify-content-start"><b>
										{{exc_lines.product_variant_option1.key}} : </b>
									{{exc_lines.product_variant_option1.value}}</span></div>
							<div *ngIf="exc_lines.product_variant_option2.value != null" class="my-1"><span
									class="align-items-start justify-content-start"><b>
										{{exc_lines.product_variant_option2.key}} : </b>
									{{exc_lines.product_variant_option2.value}}</span></div>
							<div *ngIf="exc_lines.product_variant_option3.value != null" class="my-1"><span
									class="align-items-start justify-content-start"><b>
										{{exc_lines.product_variant_option3.key}} : </b>
									{{exc_lines.product_variant_option3.value}}</span></div>
							<!-- <div class="my-1"><span class="align-items-start justify-content-start"><b>
										{{ 'orders.returns.size' | translate }}: </b>
									{{exc_lines.size}}</span></div>
							<div class="my-1"><span class="align-items-start justify-content-start"><b>
										{{ 'orders.returns.color' | translate }} : </b>
									{{exc_lines.color}}</span></div> -->
						</div>
					</div>
				</div>
			</ng-template>
		</p-virtualScroller>
	</ng-template>
</p-overlayPanel>